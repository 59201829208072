import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Grid,
  User as UsersIcon,
  DollarSign,
  Target,
  Menu as MenuBars,
  UserCheck,
  FileText,
  Users,
  Upload,
  LogOut,
  Settings,
  ChevronDown,
  ChevronUp,
  MapPin,
  MessageSquare,
  Shield,
  CreditCard,
  Code,
  Smartphone,
  Calendar,
  MessageCircle,
  Bell,
  Gift,

} from 'react-feather';
import Access from './accessManager';
// import config from '../config';
import user from '../img/user.svg';

class Menu extends Component {
  

  state = {
    title: 'Mawingu Airtime',
   menu: parseInt(window.user.customer.user_type) === 3? [
      {
        name: 'Dashboard',
        icon: <Grid size={20} />,
        link: '/',
        access: 'all',
        children: [
          {
            name: 'Home',
            link: '/home',
            access: 'all',
          },
        
       ],
      },    
      {
        name: 'Agents',
        icon: <Users size={20} />,
        link: '/agents',
        access: 'all',
      }, 
      {
        name: 'Customers',
        icon: <UsersIcon size={20} />,
        link: '/customers',
        access: 'all',
      }, 
      {
        name: 'Commission Statement',
        icon: <MapPin size={20} />,
        link: '/customer-logs',
        access: 'all',
      },
    ]: parseInt(window.user.customer.user_type) === 2? [
      {
        name: 'Dashboard',
        icon: <Grid size={20} />,
        link: '/',
        access: 'all',
        children: [
          {
            name: 'Home',
            link: '/home',
            access: 'all',
          },
        
       ],
      },
      {
        name: 'Customers',
        icon: <CreditCard size={20} />,
        link: '/customers',
        access: 'all',
      }, 
      {
        name: 'Commission Statement',
        icon: <Users size={20} />,
        link: '/customer-logs',
        access: 'all',
      },
    ]: [],
    currentRoute: window.location.pathname,
    active: 0,
  };
  render() {
    return (
      <>
        <div className='p-3 top-header d-md-none align-items-center d-flex flex-row bg-light w-100 text-dark shadow'>
          <MenuBars
            className='mr-3'
            onClick={() => {
              this.setState({ showMenu: true });
            }}></MenuBars>
          <div className='font-weight-bold top-bar-text'>
            {this.state.title}
          </div>
        </div>
        <div
          className={'menu-overlay ' + (this.state.showMenu ? 'show' : '')}
          onClick={() => {
            this.setState({ showMenu: false });
          }}></div>
        <div
          className={
            'sidebar-wrapper border-right shadow ' +
            (this.state.showMenu ? 'show' : '')
          }
          onClick={() => {
            this.setState({ showMenu: false });
          }}>
          <ul
            className='navbar-nav bg-light sidebar sidebar-dark accordion position-relative'
            id='accordionSidebar'>
            <div>
              <div className='d-flex flex-column user-login-card p-3 my-4 align-items-start'>
                <div className='d-flex flex-row align-items-start justify-content-between w-100'>
                  <div className='user-icon-parent'>
                    <img src={user} className='user-icon' alt='' />
                  </div>
                  <div className='d-flex flex-row'>
                    <div className='action-icon'>
                      {/* <Settings size={20}></Settings> */}
                    </div>

                    <div
                      className='action-icon'
                      onClick={() => window.logout()}>
                      <LogOut size={20}></LogOut>
                    </div>
                  </div>
                </div>
                <div className='mt-3 text-capitalize'>
                  {window.user.customer.cust_name}
                </div>
              
              </div>
            </div>
            
            { this.state.menu.map((d, i) => {
              return (
                <li
                  className={
                    'nav-item ' +
                    (this.state.active === i && d.children
                      ? 'pb-4 no-hover '
                      : '') +
                    (this.state.currentRoute === d.link
                      ? 'active'
                      : 'text-dark')
                  }
                  key={i}>
                  <Access permission={d.access}>
                    {' '}
                    <Link
                      to={d.link}
                      onClick={(e) => {
                        if (this.state.active === i) {
                          this.setState({ active: 0 });
                        } else {
                          this.setState({ active: i });
                        }
                        if (d.children) e.preventDefault();
                      }}
                      className={
                        'nav-link ' +
                        (this.state.currentRoute === d.link
                          ? 'active'
                          : 'text-dark')
                      }>
                      <div className='d-flex flex-row align-items-center justify-content-between'>
                        <div className='d-flex flex-row align-items-center'>
                          {d.icon}
                          <div className='text-dark ml-2 font-weight-bold link-text'>
                            {d.name}
                          </div>
                        </div>
                        {d.children && (
                          <div>
                            {this.state.active !== i && (
                              <ChevronDown size={18}></ChevronDown>
                            )}
                            {this.state.active === i && (
                              <ChevronUp size={18}></ChevronUp>
                            )}
                          </div>
                        )}
                      </div>
                    </Link>
                  </Access>
                  {this.state.active === i && d.children && (
                    <div
                      id='collapseUtilities'
                      className='collapse show shadow-sm'
                      aria-labelledby='headingUtilities'
                      data-parent='#accordionSidebar'>
                      <div className=' py-2 collapse-inner rounded mb-0'>
                        {/* <h6 className='collapse-header'>listing:</h6> */}
                        {d.children.map((d1, i1) => {
                          if (d1.type === 'hr')
                            return (
                              <>
                                <hr className={`${!d1.title || 'mb-1'}`} />
                                {d1.title && (
                                  <div>
                                    <small className='text-muted text-uppercase font-weight-bold px-4'>
                                      {d1.title}
                                    </small>
                                  </div>
                                )}
                              </>
                            );

                          return (
                            <Access permission={d1.access} key={i1}>
                              <Link
                                className={
                                  'collapse-item font-weight-bold my-1 text-capitalize ' +
                                  (this.state.currentRoute === d1.link
                                    ? 'active'
                                    : 'text-dark')
                                }
                                to={d1.link}
                                key={i1}>
                                {d1.name}
                              </Link>
                            </Access>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }

  componentWillReceiveProps(props) {
    if (props.history) {
      //   console.log(props.history);
      this.setState({ currentRoute: window.location.pathname });
    }
  }
}

export default withRouter(Menu);
