import React, { Component } from 'react';
import { ChevronDown, User, X } from 'react-feather';
import { Link } from 'react-router-dom';
import Access from './accessManager';

class Nav extends Component {
  state = {};
  render() {
    return (
      <div className='py-3 px-4 d-flex flex-row justify-content-between align-items-center d-flex flex-row nav'>
        <h2 className='font-weight-bold m-0'>{this.props.name}</h2>
        <div className='position-relative d-flex flex-row align-items-center '>
          {this.props.buttons &&
            this.props.buttons.map((d, i) => (
              <Access permission={d.permission || 'all'}>
                <Link
                  to={d.link}
                  key={i}
                  onClick={d.onClick}
                  className='mx-2 btn btn-primary rounded px-4 font-weight-bold'>
                  {d.text}
                </Link>
              </Access>
            ))}
          <div>
            <div
              className={
                'action-icon align-items-center d-md-flex flex-row d-none ' +
                (this.state.showProfile ? 'active' : '')
              }
              onClick={() =>
                this.setState({ showProfile: !this.state.showProfile })
              }>
              <div className='user-avatar d-flex flex-row align-items-center justify-content-center'>
                <User></User>
              </div>
              <ChevronDown size={17} className='ml-2' />
            </div>
            {window.user && (
              <div
                className={
                  'profile-card card shadow ' +
                  (this.state.showProfile ? 'active' : '')
                }>
                <div className='p-3 position-relative'>
                  <div className='close-action'>
                    <X className='m-2' size={17}></X>
                  </div>
                  <div className='font-weight-bold'>
                    {window.user.customer.msisdn}
                  </div>
                  <div>
                    <small className='text-capitalize'>
                      Last Logged In:{' '}
                      <code>{window.user.customer.last_login_date}</code>
                    </small>
                    <br />
                    <small className='text-capitalize'>
                      Last IP: <code>{window.user.customer.last_ip_login}</code>
                    </small>
                  </div>
                </div>
                <button
                  className='btn btn-primary text-center'
                  onClick={() => window.logout()}>
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Nav;
