import React, { Component } from "react";
import moment from "moment";
import Month from '../components/chart';
import Week from '../components/week';
import Hour from '../components/hour';
import Day from '../components/day';
import { Link } from 'react-router-dom';
import start from '../img/work.svg';
import { DollarSign, Users, Calendar, MessageCircle , User, CreditCard, Archive} from 'react-feather';
import { CardDashboard, Card } from '../components/Card';
import { Wrap, Main, NavBar } from '../components/Card/styles';


class Home extends Component {
  state = {
    chartDefaults: {
      label: 'Total users',
      fill: true,
      borderColor: '#4e73df',
      borderCapStyle: 'square'
    },

    totalclients: 0,
    totalclientsa: 0,
    totaldealers: 0,
    totalagents: 0,
    totalsales: 0,
    records: [],
    loanBalance: 0,
    performanceFilter: {},
    SalesDataset: [],
    LoansDataset: [],
    AgentsDataset: [],
    DailyDataset:[],
    WeekDataset:[],
    smallWindow: window.innerWidth,

  };

  render() {
    if (this.state.smallWindow < 750) {
      var card = '100%';
      var graph = '200%';
    } else {
      var graph = '48%';
      var card = "16%";
      var cardone = "30%";

    }


      return (
        <div>{
          window.user.customer.user_type === 3 ? (
            <div className='mx-auto'>          
            <div className='px-4 my-2'>
              <div className='d-flex flex-row align-items-center' style={{ marginTop: '70px' }}>
                <div>
                  <h1 className='mb-0 font-weight-bold text-primary'>
                    Summary Information- {window.user.customer.msisdn}
                </h1>
                </div>
              </div>


               <div className='d-flex flex-row align-items-center' style={{ marginTop: '40px' }}>
                <div>
                  <h2 className='mb-0 font-weight-bold text-primary'>
                    No Dealer Information
                </h2>
                 
                </div>
              </div>
            </div>

            <div className="px-5 py-5" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: '##ebedf5' }}>

              <CardDashboard className="red" style={{ width: card }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="title">Total Agents</div>
                      <div className="number pulsate">{this.state.totalagents.toLocaleString()}</div>
                    </div>
                    <div className="col-auto">
                      <Users size="3em" />
                    </div>
                  </div>
                </div>
              </CardDashboard>

              <CardDashboard className="blue" style={{ width: card }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="title">Total Customers</div>
                      <div className="number pulsate">{this.state.totalclients.toLocaleString()}</div>
                    </div>
                    <div className="col-auto">
                      <User size="3em" />
                    </div>
                  </div>
                </div>
              </CardDashboard>


              <CardDashboard className="green" style={{ width: card }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="title">Current Commission Available, Ksh</div>
                      <div className="number pulsate">{parseFloat(window.user.customer.commission).toLocaleString()}</div>
                    </div>
                    <div className="col-auto">
                      <Calendar size="3em" />
                    </div>
                  </div>
                </div>
              </CardDashboard>


              <CardDashboard className="orange" style={{ width: card }} >
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="title">Total Points</div>
                      <div className="number pulsate">{window.user.customer.points.toLocaleString()}</div>
                    </div>
                    <div className="col-auto">
                      <DollarSign size="3em" />
                    </div>
                  </div>
                </div>
              </CardDashboard>

              <CardDashboard className="orange" style={{ width: card }} >
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="title">Total Sales</div>
                      <div className="number pulsate">{window.user.customer.net_value.toLocaleString()}</div>
                    </div>
                    <div className="col-auto">
                      <Archive size="3em" />
                    </div>
                  </div>
                </div>
              </CardDashboard>
          

            </div>

            <div className="px-5 py-5" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: '##ebedf5' }}>
              <div style={{ width: graph }}>
                <Month
                  title='Commission Per Month'
                  updateFilter={filter => {
                    this.setState({ performanceFilter: filter });
                    
                  }}
                  datasets={this.state.SalesDataset}
                />

              </div>

              <div style={{ width: graph }}>

                <Hour
                  title='Commission Per Hour'
                  updateFilter={filter => {
                    this.setState({ performanceFilter: filter });
                  }}
                  datasets={this.state.LoansDataset}
                />
              </div>


            </div>
            <div className="px-5 py-5" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: '##ebedf5' }}>
              <div style={{ width: graph }}>
                <Day
                  title='Commission Per Day'
                  updateFilter={filter => {
                    this.setState({ performanceFilter: filter });
                    //console.log(filter);
                  }}
                  datasets={this.state.DailyDataset}
                />

              </div>

              <div style={{ width: graph }}>

                <Week
                  title='Commission Per Week'
                  updateFilter={filter => {
                    this.setState({ performanceFilter: filter });
                  }}
                  datasets={this.state.WeekDataset}
                />
              </div>


            </div>


          </div>
        

        ) : 
        (<div className='mx-auto'>          
            <div className='px-4 my-2'>
              <div className='d-flex flex-row align-items-center' style={{ marginTop: '70px' }}>
                <div>
                  <h1 className='mb-0 font-weight-bold text-primary'>
                    Summary Information- {window.user.customer.msisdn}
                </h1>
                </div>
              </div>

               <div className='d-flex flex-row align-items-center' style={{ marginTop: '40px' }}>
                <div>
                  <h2 className='mb-0 font-weight-bold text-primary'>
                    Dealer Information
                </h2>
                 <h3 className='text-primary'>
                    PhoneNumber : {window.user.customer.parent.msisdn}
                </h3>
                 <h3 className='text-primary'>
                    Name : {window.user.customer.parent.cust_name}
                </h3>
                </div>
              </div>
            </div>

            <div className="px-5 py-5" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: '##ebedf5' }}>

             

              <CardDashboard className="blue" style={{ width: cardone }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="title">Total Customers</div>
                      <div className="number pulsate">{this.state.totalclientsa.toLocaleString()}</div>
                    </div>
                    <div className="col-auto">
                      <User size="3em" />
                    </div>
                  </div>
                </div>
              </CardDashboard>


              <CardDashboard className="green" style={{ width: cardone }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="title">Current Commission Available, Ksh</div>
                      <div className="number pulsate">{parseFloat(window.user.customer.commission).toLocaleString()}</div>
                    </div>
                    <div className="col-auto">
                      <Calendar size="3em" />
                    </div>
                  </div>
                </div>
              </CardDashboard>


              <CardDashboard className="orange" style={{ width: cardone }} >
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="title">Total Points</div>
                      <div className="number pulsate">{window.user.customer.points.toLocaleString()}</div>
                    </div>
                    <div className="col-auto">
                      <DollarSign size="3em" />
                    </div>
                  </div>
                </div>
              </CardDashboard>
        

            </div>

            <div className="px-5 py-5" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: '##ebedf5' }}>
              <div style={{ width: graph }}>
                <Month
                  title='Commission Per Month'
                  updateFilter={filter => {
                    this.setState({ performanceFilter: filter });
                    
                  }}
                  datasets={this.state.SalesDataset}
                />

              </div>

              <div style={{ width: graph }}>

                <Hour
                  title='Commission Per Hour'
                  updateFilter={filter => {
                    this.setState({ performanceFilter: filter });
                  }}
                  datasets={this.state.LoansDataset}
                />
              </div>


            </div>
            <div className="px-5 py-5" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: '##ebedf5' }}>
              <div style={{ width: graph }}>
                <Day
                  title='Commission Per Day'
                  updateFilter={filter => {
                    this.setState({ performanceFilter: filter });
                    //console.log(filter);
                  }}
                  datasets={this.state.DailyDataset}
                />

              </div>

              <div style={{ width: graph }}>

                <Week
                  title='Commission Per Week'
                  updateFilter={filter => {
                    this.setState({ performanceFilter: filter });
                  }}
                  datasets={this.state.WeekDataset}
                />
              </div>


            </div>


          </div>
      )
      }
      </div>
        
          
      
        
      );
    
  }

  componentDidMount() {
    this.fetchTotals();
    this.fetchTotalsa();
    this.fetchTotalSales();
    this.fetchMonth();
    this.fetchHour();
    this.fetchDay();
    this.fetchWeek();
  }

  fetchTotals = () => {
    window.app
      .service('stats?type=total_user_types')
      .find({

      })
      .then((response) => {
         response.forEach((element, index, array) => {
          if(element.a=='Agent'){
            this.setState({ totalagents: element.b});
          }else if(element.a=='User'){
            this.setState({ totalclients: element.b});

          }else{
             this.setState({ totaldealers: element.b});

          }
          
      });
       
   

        //this.setState({ totalclients: response[1]['b'], totalagents: response[0]['b'], totaldealers: response[2]['b'] });
      })
      .catch((err) => {
        // this.setState({ table_loading: false });
        console.error(err);
      });
    };
  



  fetchTotalsa= () => {
    window.app
      .service('stats?type=total_user_types')
      .find({

      })
      .then((response) => {
       
   

        this.setState({ totalclientsa: response[0]['b'], totaldealers: response[1]['b'] });
      })
      .catch((err) => {
        // this.setState({ table_loading: false });
        console.error(err);
      });
  };  
  fetchTotalSales = () => {
    window.app
      .service('stats?type=total_sales')
      .find({

      })
      .then((response) => {
        


        this.setState({ totalsales: response[0]['total_sales'] });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  fetchMonth = () => {

    window.app
      .service('stats?type=total_month_sales')
      .find({

      })
      .then((response) => {

        let interest_gained = [];
        response.map(d => {         
          interest_gained.push({ t: d.a, y: d.b });
        });
        let datasets = [
          {
            label: 'Montly Sales',
            fill: false,
            data: interest_gained,
            backgroundColor: '#4caf50',
            borderColor: '#4caf50',
            borderWidth: 1
          }
        ];

        this.setState({ SalesDataset: datasets });

      })
      .catch((err) => {
        console.error(err);
      });
  };

  fetchHour = () => {

    window.app
      .service('stats?type=total_hour_sales')
      .find({

      })
      .then((response) => {

        let interest_gained = [];
        response.map(d => {

          interest_gained.push({ t: d.a, y: d.b });
        });       
        let datasets = [
          {
            label: 'Hourly sales',
            fill: false,
            data: interest_gained,
            backgroundColor: '#4caf50',
            borderColor: '#4caf50',
            borderWidth: 1
          }
        ];

        this.setState({ LoansDataset: datasets });

      })
      .catch((err) => {
        console.error(err);
      });
  };

  fetchDay = () => {

    window.app
      .service('stats?type=total_day_sales')
      .find({

      })
      .then((response) => {

        let interest_gained = [];
        response.map(d => {

          interest_gained.push({ t: d.a, y: d.b });
        });
       
        let datasets = [
          {
            label: 'Daily sales',
            fill: false,
            data: interest_gained,
            backgroundColor: '#4caf50',
            borderColor: '#4caf50',
            borderWidth: 1
          }
        ];

        this.setState({ DailyDataset: datasets });

      })
      .catch((err) => {
        console.error(err);
      });
  };

  fetchWeek = () => {

    window.app
      .service('stats?type=total_week_sales')
      .find({

      })
      .then((response) => {

        let interest_gained = [];
        response.map(d => {

          interest_gained.push({ t: d.a, y: d.b });
        });
       
        let datasets = [
          {
            label: 'Weekly sales',
            fill: false,
            data: interest_gained,
            backgroundColor: '#4caf50',
            borderColor: '#4caf50',
            borderWidth: 1
          }
        ];

        this.setState({ WeekDataset: datasets });

      })
      .catch((err) => {
        console.error(err);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.performanceFilter }) !==
      JSON.stringify({ ...prevState.performanceFilter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchChart();
      }, 100);
    }
  }
}

export default Home;
