import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import IdleTimer from 'react-idle-timer'

import './globals';
import './assets/css/global.css';

// pages
import Login from './pages/login';
import Home from './pages/home';
import Menu from './components/Menu';
import Alert from './components/alert';
import Dashboards from './pages/dashboards';
import Customers from './pages/users/customers';
import CustomersLogs from './pages/users/customersLogs';
import customerDetails from './pages/users/customerDetails';
import Agents from './pages/agents/customers';
import Access from './components/accessManager';

import { GitPullRequest } from 'react-feather';

// check token validity
let checkTokenValidity = () => {
  if (Date.now() / 1000 > localStorage.getItem("timeexp")) {
    window.logout(true);
    setTimeout(() => {
      alert("Your Session has expired");
    }, 0);
  } else {
    setTimeout(() => {
      //console.log("checking validity");
      if (localStorage.token) {
        checkTokenValidity();
      }
    }, 30000);
  }

};


window.global_months = [
  {
    name: "Jan",
  },
  {
    name: "Feb"
  },
  {
    name: "March",
  },
  {
    name: "April"
  },
  {
    name: "May",
  },
  {
    name: "June"
  },
  {
    name: "July",
  },
  {
    name: "August"
  },
  {
    name: "Sep",
  },
  {
    name: "Oct"
  },
  {
    name: "Nov",
  },
  {
    name: "Dec"
  },
]
window.global_days = [
  {
    name: "Sun",
  },
  {
    name: "Mon",
  },
  {
    name: "Tue"
  },
  {
    name: "Wed",
  },
  {
    name: "Thur"
  },
  {
    name: "Fri",
  },
  {
    name: "Sat"
  },
]




class App extends Component {
  state = { blur: false, authenticated: false };
  render() {
    return (
      <>

        <Alert
          onRef={(ref) => {
            window.alert2 = ref;
          }}
          toggle={(blur) => {
            this.setState({ blur });
          }}></Alert>
        <div
          className={`page-blur pa
        ge ${this.state.blur && 'blur-alert'}`}>
          <Access permission='all'>
            <Route path='/login' exact component={Login}></Route>
          </Access>

          {this.state.authenticated && (
            <Portal hist={this.props.history}></Portal>
          )}
        </div>
        <div
          className={`preloader-cover ${this.state.loaded && 'hide-preloader'
            }`}>
          <div className='alert-loader px-3 my-3 d-flex flex-row justify-content-center'>
            <div className='nest-parent'>
              <div id='nest1'></div>
            </div>
          </div>
        </div>
      </>
    );
  }


  componentDidMount() {
    window.app
      .reAuthenticate()
      .then((response) => {        
        let loaded = false;
        if (localStorage.user) loaded = true;
        this.setState({ authenticated: true, loaded });
        let path = window.location.pathname;      

        if (path.includes('login') || path.includes('get-started'))
          return false;        
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loaded: true, authenticated: false });
        let p = window.location.pathname;
        if (!['/password-change', '/password-forgot'].includes(p))
          this.props.history.push('/login');
      });
  }
}

class Portal extends Component {
  state = { idleTimer: null };
  history = this.props.hist;
  render() {
    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 60}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <div id='wrapper' className='pt-5 mt-md-0 pt-md-0 mt-2'>
          <Menu></Menu>
          <div id='content-wrapper' className='d-flex flex-column bg-light'>
            <div className='d-flex flex-fill flex-column'>
              <Access permission='all'>
                <Route path='/' exact component={Home} />
              </Access>
              <Access permission='all'>
                <Route path='/home' exact component={Home} />
              </Access>          

              <Access permission='all'>
                <Route path='/customers' exact component={Customers} />
              </Access>
              <Access permission='all'>
                <Route path='/customer-logs' exact component={CustomersLogs} />
              </Access>         
             
              <Access permission='all'>
                <Route path='/agents' exact component={Agents} />
              </Access>
              


            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    if (localStorage.token) {
      checkTokenValidity();
    }

  }
  handleOnAction = (event) => {
    console.log('user did something', event)
  }

  handleOnActive = (event) => {
    
    console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle = () => {
    window.logout(true);
    setTimeout(() => {
      alert("Your Session has expired");
    }, 0);
  }
}

export default withRouter(App);
