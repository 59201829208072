import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';

class Customers extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Agents'
          ></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            {/* <Filter
              filter={[
                {
                  label: 'Status',
                  name: 'status_id',
                  options: [
                    {
                      label: 'All',
                      value: 0,
                      default: true,
                    },

                    ...window.global_status
                      .filter((d) => d.type.includes('cust'))
                      .map((d) => {
                        return {
                          label: d.status_description,
                          value: d.id,
                        };
                      }),
                  ],
                },
              ]}
              // dateRange={['startdate', 'enddate']}
              // dateRangeLabel='Date Range'
              onChange={(filter) => {
                let { query } = this.state;

                this.setState({ query: { ...query, ...filter } });
                setTimeout(() => {
                  this.fetchCustomers();
                }, 0);
              }}
            /> */}

            <Table
              search={['msisdn', 'cust_name']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchCustomers();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
              dateRange={['startdate', 'enddate']}
              dateRangeLabel='Date Range'
              csv={() => ({
                name: 'agents',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchCustomers = () => {
    let { query } = this.state;

    this.setState({ table_loading: true });

  

    window.app
      .service('customer')
      .find({
        query: {
          ...this.state.query,
          user_type:2
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          return {
           name: d.cust_name,
            phone: d.msisdn,  
            commission: "KES "+parseFloat(d.commission).toLocaleString(), 
              
            points: d.points,    
                       
            last_activity_date: moment(d.last_activity_date).format('YYYY-MM-DD hh:mm:ss'),        
            date_created: moment(d.date_created).format('YYYY-MM-DD hh:mm:ss'),
            
            
            // action: (
            //   <Access permission='all'>
            //     <Link
            //       className='btn btn-sm btn-round btn-outline-primary px-3'
            //       to={`/agent/${d.id}/details`}>
            //       View
            //     </Link>
            //   </Access>
            // ),
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default Customers;