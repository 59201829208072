import React, { Component } from 'react';
import logo from '../img/credo.png';
import {
  User,
  Lock,
  Mail,
  Eye,
  EyeOff,
  XCircle,
  MessageSquare,
  CheckCircle,
} from 'react-feather';
import { Link } from 'react-router-dom';
import Modal from '../components/modal';
import LButton from '../components/loadingButton';
import Countdown from 'react-countdown';

class Login extends Component {
  state = {
    custom: true,
    loading: false,
    msisdn: '', 
    resetVisible: false,
    resetStatus: 0,
    loginStatus: 0,
    showPassword: false,
    verificationTab: 1,
    twoFa: '',
    showVerificationModal: false,
  };
  render() {
    return (
      <div className='d-flex flex-fill flex-column main-cover justify-content-center wallpaper'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-10 col-lg-12 col-md-9'>
              <div className='card o-hidden border-0 shadow-lg my-5 login-card'>
                <div className='card-body p-0'>
                  <div className='row'>
                    <div className='col-lg-6 bg-light d-none default-bg align-items-center justify-content-center flex-row d-md-flex icon-holder'>
                      <img src={logo} className='main-logo' alt='' />
                    </div>
                    <div className='col-lg-6'>
                      <div className='p-md-5 p-4'>
                        <div className='text-center'>
                          <h1 className='h4 text-gray-900 mb-4 font-weight-bold'>
                            <div>Customer Portal</div>
                          </h1>
                        </div>

                        <div className='text-center mb-4'>
                          <small className='text-muted'>Login To Start Your Session</small>
                        </div>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            this.login();
                          }}>
                          <div className='form-group d-flex flex-row align-items-center'>
                            <Mail
                              className='login-icon align-self-center position-absolute ml-2'
                              color='gray'
                              size={18}
                            />

                            <input
                              type='text'
                              className='form-control form-control-user  icon-input'
                              placeholder='Phone Number'
                              value={this.state.msisdn}
                              required={true}
                              onChange={(e) => {
                                this.setState({ msisdn: e.target.value });
                              }}
                            />
                          </div>
                        
                          <div className='text-center'>
                            <div className='d-inline-block'>
                              <LButton
                                text='Login'
                                status={this.state.loginStatus}></LButton>
                            </div>
                          </div>
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal visible={this.state.showVerificationModal} dismiss={true}>
          <div className='text-center '>
            {/* {this.state.verificationTab === 1 && (
              <div className='verify-container'>
                <h3 className='mb-4'>Verify Login</h3>

                <p className='text-whitesmoke'>
                  Choose how you would like to validate your login
                </p>

                <div className='d-flex flex-column align-items-center'>
                  <button
                    className='btn btn-outline-primary btn-round px-5 my-3'
                    onClick={() => this.setState({ verificationTab: 2 })}>
                    <MessageSquare className='mr-2'></MessageSquare>
                    SMS Message
                  </button>
                  <button
                    className='btn btn-outline-primary btn-round px-5 my-3'
                    disabled={true}>
                    <Mail className='mr-2'></Mail>
                    Email Message
                  </button>
                  <button
                    className='btn btn-outline-danger btn-round px-5 my-3'
                    disabled={true}>
                    Continue with google
                  </button>
                </div>
              </div>
            )} */}

            {this.state.verificationTab === 2 && (
              <div className='verify-container '>
                <h3 className='mb-4'>Verification code</h3>

                <p className='text-whitesmoke'>
                  A verification code has been sent to your mobile device. Enter
                  the code below in order to proceed.
                </p>
                <form
                  action=''
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.verify();
                  }}>
                  <div className='d-flex flex-row align-items-center '>
                    <MessageSquare className='position-absolute text-whitesmoke ml-2'></MessageSquare>

                    <input
                      type='text'
                      className='form-control code-input my-4 text-center pl-3'
                      onChange={(e) => {
                        this.setState({ twoFa: e.target.value });
                      }}
                      required
                    />
                  </div>
                  <div className='text-whitesmoke'>
                    <Countdown
                      daysInHours={true}
                      date={Date.now() + 300000}
                      onComplete={() => {
                        this.setState({ verificationTab: 1 });
                        window.alert2.notify({
                          title: 'Verification code has timed out',
                          description:
                            'Please try again by selecting any of the provided options.',
                          autoCloseDuration: 10000,
                        });
                      }}
                    />
                  </div>
                  <button className='btn btn-primary px-4 btn-round mt-3'>
                    Submit
                  </button>
                </form>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }

  login = async () => {
  
    this.setState({ loginStatus: 1 });
    let { msisdn } = this.state;

    window.app
      .authenticate({
        strategy: 'local',
        msisdn,
        
      })
      .then((response) => {
        // console.log(response);

        // localStorage.user = JSON.stringify(response);
        window.alert2.show({
          loader: false,
          icon: <CheckCircle size={60} className=''></CheckCircle>,
          title: 'Verfied Successfully',
          message: 'Welcome to Back ' + response.admin.name,
          buttons: true,
        });

        window.location = '/';
      })
      .catch((err) => {
        this.setState({ loginStatus: 0 });
        console.log(err);
        // err.data.channel
        localStorage.clear();

        if (err.data) {
          if (err.data.code == 1) {
            this.setState({
              loginStatus: 2,
              verificationTab: 2,
              showVerificationModal: true,
            });
          } else {
            window.alert2.show({
              loader: false,
              icon: <XCircle size={60} className=''></XCircle>,
              title: 'Invalid Credentials!',
              message: 'Wrong Phone Number',
              buttons: true,
            });
          }
        } else {
          window.alert2.show({
            loader: false,
            icon: <XCircle size={60} className=''></XCircle>,
            title: 'Login Error!',
            message: 'Wrong Phone Number',
            buttons: true,
          });
        }
      });
  };

  verify = () => {
    let { msisdn } = this.state;
    console.log('msisdn',this.state.msisdn)

    window.alert2.show({
      loader: true,
      title: 'Verifying ...',
      buttons: false,
    });

    window.app
      .authenticate({
        strategy: 'local',
        msisdn: this.state.msisdn,       
        twoFa: this.state.twoFa,
      })
      .then((response) => {
         console.log('this is',response.authentication.payload.exp);
        localStorage.token = response.accessToken;
        localStorage.user = JSON.stringify(response);
        localStorage.setItem("timeexp", JSON.stringify(response.authentication.payload.exp))
      
        window.alert2.show({
          loader: false,
          icon: <CheckCircle size={60} className=''></CheckCircle>,
          title: 'Verfied Successfully',
          message: 'Welcome to Back ',
          buttons: true,
        });

        window.location = '/';
      })
      .catch((err) => {       
        this.setState({ loginStatus: 0 });
        console.log(err);
        window.alert2.show({
          loader: false,
          icon: <XCircle size={60} className=''></XCircle>,
          title: 'Verification Error!',
          message: 'Invalid Code Entered',
          buttons: true,
        });
      });
  };
}

export default Login;
