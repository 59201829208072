import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import Filter from '../../components/filter';
import Modal from '../../components/modal';
import ReactJson from 'react-json-view';

import moment from 'moment';

class Transactions extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        {!this.props.user && <Nav name='Transactions'></Nav>}
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Filter
            
              dateRange={['startdate', 'enddate']}
              dateRangeLabel='Date Created'
              onChange={(filter) => {
                this.setState({ filter });
                setTimeout(() => {
                  this.fetchTransactions();
                }, 0);
              }}
            />
            <Table
              search={['initiator_id', 'account_no']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(query) => {
                this.setState({ query });
                setTimeout(() => {
                  this.fetchTransactions();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>

          {this.state.json && (
            <Modal
              visible={true}
              close={() => {
                this.setState({ json: null });
              }}>
              <ReactJson
                displayDataTypes={false}
                displayObjectSize={false}
                src={this.state.json}
              />
            </Modal>
          )}
        </div>
      </div>
    );
  }

  fetchTransactions = () => {
    this.setState({ table_loading: true });
 

    let { query, filter } = this.state;
    let q = { ...query, ...filter };
   

    if (q.startdate) {
      q.createdAt = {
        $gte: q.startdate,
        $lte: q.enddate,
      };
      delete q.startdate;
      delete q.enddate;
    }

    // q.client_id = this.props.user;

    window.app
      .service('transaction-requests')
      .find({
        query: {
          ...q,
          initiator_id: this.props.user,
        },
      })
      .then((response) => {
        console.log(response);

        response.data = response.data.map((d) => {
          return {
            // "requestId": d.requestId,               
            "initiator": d.initiator_id,
            "reference": d.reference_id,
             "denomination_value":"KES "+parseFloat(d.denomination_value).toLocaleString(),
            "account_no": d.account_no,        
            "provider": d.provider,
            "response": d.response,
            response: d.response && (
              <button
                className='btn btn-sm btn-outline-success font-weight-bold'
                onClick={() => this.setState({ json:d })}>
                view
              </button>
            ),           
            date_created: moment(d.createdAt).utcOffset('GMT+3').format('YYYY-MM-DD hh:mm:ss'),
           
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default Transactions;
